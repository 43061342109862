import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

import Fade from 'react-reveal/Fade'

import { FiArrowRight, FiArrowUpRight } from 'react-icons/fi'

import MORE_TO_COME from '../img/more_to_come.png'

class Shows extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    let allPosts = data.allMdx.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension no_bg press aligncenter">
            <div className="bg">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={'25%'}>
                  <h2>Followed by millions</h2>
                </Fade>

                <Fade bottom duration={1000} delay={250} distance={'25%'}>
                  <p>Generating billions</p>
                </Fade>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title="Our shows"
          keywords={[
            'Local Steals and Deals',
            'Gangas and Deals',
            'Brand deals',
          ]}
          description="From Local Steals and Deals to Gangas and Deals and everything in between, our shows have the best brand deals in North America. "
        />

        <div className="page-content pages-shows">
          <div className="section-shows">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <div className="columns is-multiline">
                    {allPosts
                      .filter(({ node }) => node.frontmatter.hidden !== true)
                      .map(({ node }, i) => {
                        const hasEpisodes =
                          node.frontmatter.episodes &&
                          node.frontmatter.episodes.length !== 0
                        const Post = (
                          <>
                            <div className="cp-img">
                              <img src={node.frontmatter.thumbnail} />

                              <video
                                width="100%"
                                height="100%"
                                controls={false}
                                loop
                                autoPlay
                              >
                                <source
                                  src={node.frontmatter.video}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>

                            <div className="cp-content">
                              <h3>{node.frontmatter.title}</h3>

                              <p className="cp-description">
                                {node.frontmatter.description}
                              </p>

                              <i>
                                {hasEpisodes ? (
                                  <>
                                    See all segments <FiArrowRight />
                                  </>
                                ) : (
                                  <>
                                    See more <FiArrowUpRight />
                                  </>
                                )}
                              </i>
                            </div>
                          </>
                        )

                        return (
                          <Fade
                            key={node.fields.slug}
                            duration={600}
                            top
                            distance={`${(i % 2) * 5}%`}
                          >
                            <div className="column is-half">
                              <div className="show-item">
                                {hasEpisodes && (
                                  <Link to={node.fields.slug}>{Post}</Link>
                                )}

                                {!hasEpisodes && (
                                  <a
                                    href={node.frontmatter.link}
                                    target="_blank"
                                  >
                                    {Post}
                                  </a>
                                )}
                              </div>
                            </div>
                          </Fade>
                        )
                      })}

                    <Fade
                      duration={600}
                      top
                      distance={`${(allPosts.length % 2) * 5}%`}
                    >
                      <div className="column is-half">
                        <div className="show-item more-to-come">
                          <div className="cp-img">
                            <img src={MORE_TO_COME} />
                          </div>

                          <div className="cp-content">
                            <h3>More to come</h3>

                            <p className="cp-description">
                              We’re working on our next big launch, so stay
                              tuned!
                            </p>

                            <i>Coming soon</i>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PreFooter
          title="Want these channels to grow your business?"
          linkText="Get Knocking"
          linkTo="/contact"
        />
      </Layout>
    )
  }
}

export default Shows

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "shows" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            description
            hidden
            episodes {
              date(formatString: "MMMM DD, YYYY")
              title
              vimeo_id
              image
            }
            link
            title
            thumbnail
            video
          }
        }
      }
    }
  }
`
